import htmlLogo from "./images/html.svg";
import cssLogo from "./images/css.svg";
import instagramIcon from "./images/instagram.svg";
import reactLogo from "./images/react.svg";
import awsCloudLogo from "./images/aws-cloud.svg";
import linkedinLogo from "./images/linkedin.svg";
import javascriptLogo from "./images/javascript.svg";
import k8sLogo from "./images/k8s.svg";
import springBootLogo from "./images/spring-boot.svg";
import javaLogo from "./images/java-logo.svg";
import elasticLogo from "./images/elasticLogo.svg";

const yourData = {
  name: "Rosbel",
  headerTagline: [
    "Software Engineer",
    "6 years of experience",
    "building software",
  ],
  headerParagraph: null,
  contactEmail: "hello@rosbelsanroman.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Project One",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here

      imageSrc:
        "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",

      url: "http://google.com/",
    },
    {
      title: "Project Two",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      imageSrc:
        "https://images.unsplash.com/photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      url: "http://google.com/",
    },
    {
      title: "Project Three",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here

      imageSrc:
        "https://images.unsplash.com/photo-1504083898675-c896ecdae86e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",

      url: "http://fortnite.com/",
    },
    {
      title: "Project Four",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      imageSrc:
        "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      url: "http://apple.com/",
    },
    {
      title: "Project Five",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      imageSrc:
        "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      url: "http://ti.com/",
    },
    {
      title: "Project Six",
      para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      imageSrc:
        "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
      url: "http://reddit.com/",
    },
  ],
  aboutParaOne: "My name is Rosbel Sanroman",
  aboutParaTwo:
    "This section is still in progress.. Will be updated with background about my experience as a software engineer.",
  aboutParaThree:
    "This section is still in progress.. Will be updated with background about my experience as a software engineer.",
  aboutImage: "https://i.imgur.com/6dSybYTl.jpg",
  skills: [
    {
      img: htmlLogo,
      para: "HTML",
    },
    {
      img: cssLogo,
      para: "CSS",
    },
    {
      img: javascriptLogo,
      para: "Javascript",
    },
    {
      img: reactLogo,
      para: "React",
    },
    {
      img: awsCloudLogo,
      para: "AWS",
    },
    {
      img: k8sLogo,
      para: "Kubernetes",
    },
    {
      img: javaLogo,
      para: "Java",
    },
    {
      img: springBootLogo,
      para: "SpringBoot",
    },
    {
      img: elasticLogo,
      para: "ElasticSearch",
    },
    // { // not adding this yet, because it looks better with multiples of 9
    //   img: null,
    //   para: "MongoDB"
    // }
  ],
  social: [
    {
      img: linkedinLogo,
      url: "https://linkedin.com/rosbelsanroman",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/rosbelsanroman",
    },
  ],
};

export default yourData;
