import React from "react";

import Seo from "../components/seo";
import Header from "../components/Header";
import Work from "../components/Work";
import About from "../components/about";
import Skills from "../components/skills";
import Footer from "../components/Footer";

const IndexPage = () => (
  <>
    <Seo title="rosbel.io" />
    <Header />
    <Work />
    <About />
    <Skills />
    <Footer />
  </>
);

export default IndexPage;
